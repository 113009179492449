import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import DarkThemeMImg from "../../assets/images/dark-theme/dark-theme-m.webp"
import EasyOnEyesMImg from "../../assets/images/dark-theme/easy-on-eyes-m.webp"
import BatterySaverImg from "../../assets/images/dark-theme/battery-saver.webp"
import BatterySaverMImg from "../../assets/images/dark-theme/battery-saver-m.webp"
import ABreakMImg from "../../assets/images/dark-theme/a-break-m.webp"

const DarkTheme = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="Dark Theme | BlueMail App"
        postDescription="Seamlessly switch between day and night mode with ease making it easier to handle those late night emails."
        postImage="/img/OG/og_image-Dark-Theme.png"
        postURL="features-functions/dark-theme"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid dark-theme w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
              <h1>
                Embrace the Night with <span>Dark Theme</span>
              </h1>
              <p>
                Transition smoothly from the bright clarity of day to the calm aura of night with our Dark Theme. Tailor your email experience to the time of day without lifting a finger—BlueMail's Dark Mode aligns with the sunset in your locale, ensuring your eyes get a rest just as the stars begin to twinkle.
              </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={DarkThemeMImg} alt="Dark Theme" />
            </div>
          </div>
        </div>
        <div className="container-fluid easy-on-eyes w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
              <h2>
                <span>Gentle</span> Illumination for Nighttime Use
              </h2>
              <p>
                Dive into a comfortable reading experience when the lights go down. Our Dark Theme is a balm for your eyes, dimming the harsh whites into soothing shades. It's not just about aesthetics—it's about wellness. For those sensitive to light or seeking serenity before sleep, this feature promises a screen that harmonizes with your evening ambiance, helping you wind down in tandem with the day.
              </p>

              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={EasyOnEyesMImg} alt="Easy on the eyes" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
              <h2>
                Power <span>Efficiency</span> with Dark Theme
              </h2>
              <p>
                Extend your device's battery life effortlessly with our energy-conscious Dark Theme. On OLED screens, the deep blacks of this theme mean fewer pixels at work, translating to significant power savings. It's smart technology for sustainability—keeping your device running longer while taking a step towards energy conservation.
              </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img
                className="d-992"
                src={BatterySaverImg}
                alt="Battery Saver"
              />
              <img
                className="w-991 mt-3 mb-5"
                src={BatterySaverMImg}
                alt="Battery Saver mobile"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid a-break w-707 r mb-100">
          <div className="container">
            <div className="row">
            <div className="col-lg-6 col-md-6 col-xs-12">
            <h2>
              Embrace the Pause with <span>Dark Theme</span>
            </h2>
            <p>
              While our Dark Theme offers visual comfort, we advocate for holistic eye care. Regular screen breaks and proper ambient lighting are essential companions to reduce eye strain. So, go ahead—give your eyes the respite they deserve.
            </p>
          </div>

            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5">
              <img src={ABreakMImg} alt="Take a break" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default DarkTheme

export const query = graphql`
  query DarkThemePageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
